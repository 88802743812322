import React, { useState, useCallback, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/Select';
import { Container, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link, CardActionArea, CardActions, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import { experimentalStyled as styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const PedalFilter = ({ pedals }) => {
  const [filteredPedals, setFilteredPedals] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [countryFilter, setCountryFilter] = useState('');
  const [brandFilter, setBrandFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedCard, setExpandedCard] = useState(null);

  const handleCountryChange = (e, SelectChangeEvent) => {
    const newCountry = e.target.value;
    setCountryFilter(newCountry);
    // Reset brand filter when country changes
    setBrandFilter('');
    setPage(1); // Reset pagination
  };

  const handleBrandChange = (e) => {
    setBrandFilter(e.target.value)
    setTypeFilter('');
    setPage(1); // Reset pagination
  };
  const handleTypeChange = (e) => setTypeFilter(e.target.value);
  const handleSearchChange = (e) => setSearchQuery(e.target.value.toLowerCase());

  // Get unique countries and types
  const uniqueCountries = [...new Set(pedals.map(pedal => pedal.country))];
  // const uniqueTypes = [...new Set(pedals.map(pedal => pedal.type))];

  // Get unique brands based on the selected country
  const uniqueBrands = [...new Set(
    pedals
      .filter(pedal => !countryFilter || pedal.country === countryFilter)
      .map(pedal => pedal.brand)
  )];

  const uniqueTypes = [...new Set(
    pedals
      .filter(pedal => !brandFilter || pedal.brand === brandFilter)
      .map(pedal => pedal.type)
  )];

  const loadMorePedals = useCallback(() => {
    const filtered = pedals.filter(pedal => {
      return (
        (countryFilter === '' || pedal.country === countryFilter) &&
        (brandFilter === '' || pedal.brand === brandFilter) &&
        (typeFilter === '' || pedal.type === typeFilter) &&
        (searchQuery === '' || pedal.pedal_name.toLowerCase().includes(searchQuery))
      );
    });


    const newPedals = filtered.slice(0, page * 20); // Adjust chunk size as needed
    setFilteredPedals(newPedals);
    setHasMore(filtered.length > newPedals.length);
  }, [pedals, page, countryFilter, brandFilter, typeFilter, searchQuery]);

  const handleExpandClick = (index) => {
    setExpandedCard(expandedCard === index ? null : index);
  };


  useEffect(() => {
    loadMorePedals();
  }, [loadMorePedals]);

  return (
    <div>
      <div>

        <TextField id="outlined-basic" label="Search" variant="outlined" value={searchQuery} onChange={handleSearchChange} />


        <hr></hr>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} position="sticky">

          <Grid xs={4}>
            <Box sx={{ minWidth: 80, maxWidth: 160 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <NativeSelect value={countryFilter}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select" onChange={handleCountryChange}
                >
                  {uniqueCountries.map(country => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>

          <Grid xs={4}>
            <Box sx={{ minWidth: 80, maxWidth: 160 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Brands</InputLabel>
                <NativeSelect value={brandFilter}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select" onChange={handleBrandChange}
                >
                  {uniqueBrands.map(brand => (
                    <MenuItem key={brand} value={brand}>
                      {brand}
                    </MenuItem>
                  ))}
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>

          <Grid xs={4}>
            <Box sx={{ minWidth: 80, maxWidth: 160 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <NativeSelect value={typeFilter}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select" onChange={handleTypeChange}
                >
                  {uniqueTypes.map(type => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>

        </Grid>

        <hr></hr>

      </div>


      <InfiniteScroll
        dataLength={filteredPedals.length}
        next={() => setPage(prev => prev + 1)}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={<p>No more data to load</p>}
      >
        <Box sx={{ width: "100%" }}  >
          <Grid container rowSpacing={4} columnSpacing={{ xs: 4, sm: 4, md: 4 }}>
            {filteredPedals.map((pedal, index) => (

              <Grid xs={12} md={6} key={index}>

                <Card sx={{ minWidth: 126 }} >
                  <CardActionArea >
                    <CardMedia
                      component="img"
                      height="280"
                      image={pedal.img ? pedal.img : 'https://placehold.co/600x400?text=Pedal+Sounds'}
                      alt="pedal sounds"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {pedal.pedal_name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {pedal.brand} from ({pedal.country}) - {pedal.type}- {pedal.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Link href="#" underline="hover">
                      {'Share'}
                    </Link>
                  </CardActions>
                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expandedCard === index}
                      onClick={() => handleExpandClick(index)}
                      aria-expanded={expandedCard === index}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expandedCard === index} timeout="auto" unmountOnExit>
                    <CardContent>
                      {/* Expanded content */}
                      <Typography paragraph
                      dangerouslySetInnerHTML={{__html: pedal.fulldescription ? pedal.fulldescription  : 'No description yet!!!'}}>
                       </Typography>
                       <CardMedia
                      component="img"
                      width="256"
                      image={pedal.img ? pedal.img : 'https://placehold.co/600x400?text=Pedal+Sounds'}
                      alt="pedal sounds"
                    />
                      
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>


            ))}
          </Grid>
        </Box>

      </InfiniteScroll>


    </div>
  );
};

export default PedalFilter;
